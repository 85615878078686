import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { injectIntl } from "gatsby-plugin-intl";

const NotFoundPage = ({intl}) => (
  <Layout>
    <Seo title={intl.formatMessage({ id: "title" })} />
    <h1>404: {intl.formatMessage({ id: "404.header" })}</h1>
    <p>{intl.formatMessage({ id: "404.description" })}</p>
  </Layout>
)

export default injectIntl(NotFoundPage)